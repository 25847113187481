<template>
    <section class="section is-custom-padding">
        <div class="container">
            <div class="box has-text-right" v-bind:class="{ 'has-background-success-light': WarningObject.seen, 'has-background-danger-light': !WarningObject.seen }">
                <b-button v-if="GetCurrentUser.permissions.alertsremoving && !WarningObject.thumbsUp" class="is-floating-left" size="is-small" type="is-danger" icon-right="thumbs-down" @click="RemoveWarning" rounded inverted />
                <b-button v-if="GetCurrentUser.permissions.alertsremoving" class="is-floating-right" size="is-small" type="is-success" icon-right="thumbs-up" @click="MarkThumbsUpWarning()" rounded :inverted="!WarningObject.thumbsUp" :disabled="WarningObject.thumbsUp" />
                <div class="columns">
                    <div class="column is-9 has-text-left">
                     
                        <div>
                            <div class="block">
                                <b-icon pack="fas" v-for="(warningData, index) in GetWarningDataObject" v-bind:key="index" :icon="warningData.icon" type="is-primary" size="is-medium">
                                </b-icon>
                            </div>
                        </div>
                        <p class="has-text-weight-bold is-size-5 is-size-7-mobile has-text-left-mobile">
                            <span v-for="(warningData, index) in GetWarningDataObject" v-bind:key="index">
                                {{ warningData.ocurrencies }}
                                {{ warningData.type }}
                                <span v-if="index != GetWarningDataObject.length - 1">{{ $t('warnings.card.conjunction') }}</span> <!-- Does not show letter "e" when it's the last element. -->
                            </span>

                            {{ $tc('warnings.card.detected', GetWarningTotalOcurrencies) }}
                        </p>
                        <h2 class="is-size-7">
                            <p>ID: {{ WarningObject.datetime }}</p>
                            <p v-if="GetCurrentUser.role !== 'visualizer' && WarningObject.operator">Operador: {{ WarningObject.operator }}</p>
                            <!-- <span v-if="WarningObject.lla.name">{{ $t('warnings.card.point') }}: {{ WarningObject.lla.name }}</span> -->
                        </h2>
                    </div>
                    <div class="column is-3 has-text-left-mobile">                        
                        <figure class="image is-pointer-cursor" @click="ShowHistoryModal"><img class="is-rounded" :src="GetImageDownloadLink">
                            <b-loading :is-full-page="false" :active.sync="IsImageLoading" :can-cancel="true"></b-loading>
                        </figure>
                    </div>
                </div>
                <div>
                    <b-table class="has-text-left" :data="[WarningObject]">
                        
                        <b-table-column field="WarningObject.datetime" label="Data"><span class="is-size-7-mobile">{{ new Date(WarningObject.datetime).toLocaleDateString(GetLocaleChange) }}</span></b-table-column>
                        <b-table-column field="WarningObject.datetime" label="Hora"><span class="is-size-7-mobile">{{ new Date(WarningObject.datetime).toLocaleTimeString(GetLocaleChange) }}</span></b-table-column>
                        <b-table-column field="WarningObject.ptz.presetID" label="Preset" v-if="WarningObject.ptz"><span class="is-size-7-mobile">
                                {{WarningObject.ptz.presetID}}
                            </span>
                        </b-table-column>
                        <b-table-column field="WarningObject.lla.latidude" label="Latitude" >
                            <span class="is-size-7-mobile" v-if="WarningObject.lla">{{ WarningObject.lla.latitude }}</span>
                            <span class="is-size-7-mobile" v-else>-</span>
                        </b-table-column>
                        <b-table-column field="WarningObject.lla.longitude" label="Longitude">
                            <span class="is-size-7-mobile" v-if="WarningObject.lla">{{ WarningObject.lla.longitude }}</span>
                            <span class="is-size-7-mobile" v-else>-</span>
                        </b-table-column>
                        <b-table-column field="Maps" label="Link externo" >
                            <span v-if="WarningObject.lla" class="is-size-7-mobile"><a @click="OpenedExternalLink()" :href="'https://www.google.com/maps/search/?api=1&query=' + WarningObject.lla.latitude + ',' + WarningObject.lla.longitude" target="_blank">Abrir no Google Maps <b-icon pack="fas" icon="external-link-alt" size="is-small">
                                    </b-icon></a></span>
                                    
                            <span class="is-size-7-mobile" v-else>-</span>
                        
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
        <b-modal :active.sync="IsWarningHistoryModalVisible" full-screen has-modal-card >            
            <div class="centered" v-if="!fullImg"> 
                <div class="columns is-multiline is-gapless" >                
                    <div @click="expandedImage" class="column is-half">
                        <figure class="image " >
                            <img :src="GetImageDownloadLink">
                        </figure>
                    </div>
                    <div class="column is-half">
                        <div class="is-100-percent-height">
                            <CardWarningDetailsModal :WarningLocation="WarningObject.lla" />                            
                        </div>
                    </div>
                </div>
            </div>

            <div @click="expandedImage" class="is-100-percent-height" v-else>
                <figure class="image figure-full-screen-wide" v-if="GetCurrentUser.activeFlag == 'Altave'">                                          
                    <img :src="GetImageDownloadLink"  />  
                </figure>
                <figure class="image figure-full-screen-4by3" v-else>                                          
                    <img :src="GetImageDownloadLink"  />  
                </figure>
                
            </div>
        </b-modal>
    </section>
</template>
<style lang="scss">
.centered{
    margin-top: 8%;
}
.container.is-overflow {
    overflow-x: auto;   
}
.figure-full-screen-wide{    
    width: 134vh;
    // width: 175vh;

    height: auto;       
    margin-left: auto;
    margin-right: auto;
}
.figure-full-screen-4by3{    
 
    width: 175vh;

    height: auto;       
    margin-left: auto;
    margin-right: auto;
}

.is-100-percent-height {
    height: 100%;
    
    
}

.is-pointer-cursor {
    cursor: pointer;
}

.section.is-custom-padding {
    padding-top: 0;
}

.image img.is-rounded {
    border-radius: 12px !important;
}

.is-floating-left {
    float: right;
    margin-top: -30px;
    margin-right: -30px;
    display: inline-block;
}

.is-floating-right {
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    display: inline-block;
}
</style>
<script>

import CardWarningDetailsModal from '@/components/warning/CardWarningDetailsModal.vue'
export default {
    name: 'CardWarning',
    components: {
        CardWarningDetailsModal
    },
    props: {
        WarningObject: Object
    },
    data() {
        return {
            IsWarningHistoryModalVisible: false,
            ImageDownloadLink: '',
            IsImageLoading: true,
            fullImg:true
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetWarningDataObject() {
            let warningDataObject = []
            for (let index in this.WarningObject.alerts) {
                
                switch (index) {
                    case '1':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.person', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'running',
                        })}
                        break
                    case '2':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.vehicle', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'car',
                        })}
                        break
                    case '3':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.interest', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'map-marker-alt',
                        })}
                        break
                    case '4':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.hotPoint', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'thermometer-full',
                        })}
                        break
                    case '5':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.light', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'bolt',
                        })}
                        break
                    case '6':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.boat', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'ship',
                        })}
                        break
                    case '7':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: this.$i18n.tc('warnings.card.types.fire', this.WarningObject.alerts[index]),
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'fire',
                        })}
                        break
                    case '8':
                        if(this.WarningObject.alerts[index]>0){
                        warningDataObject.push({
                            type: 'Rezone',
                            ocurrencies: this.WarningObject.alerts[index],
                            icon: 'users-slash',
                        })}
                        break
                }
            }
            return warningDataObject
        },
        GetWarningTotalOcurrencies() {
            let totalWarningOcurrencies = 0
            for (let index in this.WarningObject.alerts) {
                totalWarningOcurrencies += this.WarningObject.alerts[index]
            }
            return totalWarningOcurrencies
        },
        GetImageDownloadLink() {
            return this.ImageDownloadLink
        },
        GetLocaleChange() {
            return this.$i18n.locale
        }
    },
    watch: {
        GetImageDownloadLink: {
            handler(url) { 
            if (url) this.IsImageLoading = false }
        },
    },
    methods: {
        expandedImage(){
            this.fullImg=!this.fullImg
        },
        ShowHistoryModal() {
            this.MarkSeenWarning()
            this.IsWarningHistoryModalVisible = true
            this.fullImg=false
            this.$gtag.event('open_warning_image', { 'event_category': 'warnings' })
        },
        RetrieveImageDownloadLink() {
            let warningDateString = new Date(this.WarningObject.datetime).toLocaleDateString('pt-BR').split('/').join('-')
            let userFlag = this.GetCurrentUser.activeFlag.toLowerCase()
            this.$store.dispatch('storage/GetSauronImageDownloadLink', userFlag + '-backlog/' + warningDateString + '/' + this.WarningObject.key).then(url => {
                this.ImageDownloadLink = url}).catch(() => {
                    this.$store.dispatch('storage/GetSauronImageDownloadLink', userFlag + '/' + warningDateString + '/' + this.WarningObject.key).then(url => {
                        this.ImageDownloadLink = url }).catch(() => {
                            this.$store.dispatch('storage/GetSauronImageDownloadLink', userFlag + '-backlog/' + warningDateString + '/' + this.WarningObject.key.replace("noidx","ACCC8ED12FB7")).then(url => {
                                this.ImageDownloadLink = url}).catch(()=>{
                                    this.$store.dispatch('storage/GetSauronImageDownloadLink', userFlag + '-backlog/' + warningDateString + '/' + this.WarningObject.key.replace("ACCC8ED12FB7",this.WarningObject.idx)).then(url=>{
                                        this.ImageDownloadLink = url
                                        })
                                    })
                                })
                            }) 
        },
        RemoveWarning() {
            this.$buefy.dialog.confirm({
                title: 'Remover alerta',
                message: 'Tem certeza de que deseja remover o alerta <b>' + this.WarningObject.datetime + '</b>?',
                confirmText: 'Confirmar remoção',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    let warningDateString = new Date(this.WarningObject.datetime).toLocaleDateString('pt-BR').split('/').join('-')
                    this.WarningObject.whoRemoved = this.GetCurrentUser.email
                    this.$store.dispatch('database/RemoveWarningFromDatabase', { warning: this.WarningObject, warningId: this.WarningObject.key, date: warningDateString })
                        .then(() => {
                            this.$gtag.event('click_warning_dislike', { 'event_category': 'warnings' })
                            if (this.WarningObject.seen) this.$emit('SeenWarningRemoved')
                            else this.$emit('UnseenWarningRemoved')
                        })
                }
            })
        },
        MarkSeenWarning() { //TODO: MERGE WITH MARKTHUMBSUPWARNING.
            if (this.WarningObject.seen) return
            let warningDateString = new Date(this.WarningObject.datetime).toLocaleDateString('pt-BR').split('/').join('-')
            let warningIdxName = this.WarningObject.idxname.toLowerCase().replace('-backlog','')
            let warningIdx = this.WarningObject.idx
            let warningId = this.WarningObject.key
            this.$store.dispatch('database/MarkSeenWarning', { warningDateString: warningDateString, warningIdxName: warningIdxName, warningIdx: warningIdx, warningId: warningId })
                .then(() => {                     
                    this.$emit('WarningSeen') })
        },
        MarkThumbsUpWarning() {
            if (this.WarningObject.thumbsUp) return
            let warningDateString = new Date(this.WarningObject.datetime).toLocaleDateString('pt-BR').split('/').join('-')
            let warningIdxName = this.WarningObject.idxname.toLowerCase().replace('-backlog','')
            let warningIdx = this.WarningObject.idx
            let warningId = this.WarningObject.key
            this.$store.dispatch('database/MarkThumbsUpWarning', { warningDateString: warningDateString, warningIdxName: warningIdxName, warningIdx: warningIdx, warningId: warningId })
                .then(() => {
                    this.$gtag.event('click_warning_like', { 'event_category': 'warnings' })
                    this.WarningObject.thumbsUp = true
                    this.ShowHistoryModal()
                })
        },
        OpenedExternalLink() {
            this.$gtag.event('open_warning_external_link', { 'event_category': 'warnings' })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({ //Displays toast with given properties below.
                message: 'Error: ' + message, //Message to display.
                type: type, //Toast's color.
                position: 'is-bottom', //Toast's position.
                duration: 3000, //Toast's duration.
            })
        }
    },
    created() {
        this.RetrieveImageDownloadLink()
    }
}
</script>