<template>
    <div>
        <TheNavbar />
        <!-- Seção criada exclusivamente para o cliente Ocyan -->
        <!-- <section class="section" v-if="GetCurrentUser.activeFlag.toLowerCase()=='ocyan'" > -->
        <section class="section" v-if="flagType.type=='offshore'" >
            
            <div class="container is-flex is-flex-elements-centered">
                <b-field label-position="inside" grouped group-multiline position="is-centered">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">{{ $t('warnings.calendar.label') }}</span>
                    </template>
                    <b-datepicker v-model="SelectedDateOcyan" :month-names="GetCalendarLocale.MonthNames" :day-names="GetCalendarLocale.DayNames" :selectable-dates="warningsDatesOcyan" trap-focus>
                    </b-datepicker>
                    
                    <p class="control">
                        <b-button class="button is-primary" @click="GetSelectedDateWarningsOcyan({stayOnSamePage: false, ignoreCacheCheck: false})" expanded><span class="is-family-sans-serif">{{ $t('warnings.calendar.button') }}</span></b-button>
                    </p>             
                   
                </b-field>
            </div>
            
                <div class="columns section is-multiline" v-if="warningsOcyan">              
                    
                <div v-for="alerts in GetCurrentPageWarningsOcyan" :key="alerts" class="column is-6  ">

            <div class="box has-background-white section is-flex-elements-centered">
                <div class="has-text-right mb-4">
                    
                    <b-button disabled size="is-small" icon-right="thumbs-up" :type="alerts.thumb_up=='false'? 'is-dark':'is-success'" class="is-floating-right-ocyan" rounded inverted />                    
                    <b-button disabled size="is-small" icon-right="thumbs-down" class="is-floating-left-ocyan" rounded inverted  :type="alerts.thumb_down=='false'? 'is-dark':'is-danger'"/>
                </div>
                <div class="columns is-paddingless">
                <div class="column is-4 has-text-left">
                    <div class="container has-text-underlined">
                    <b-icon  class="has-right-space" icon="hard-hat" type="is-primary"></b-icon>
                    <span  class="is-size-6 has-text-weight-bold has-text-primary"> {{alerts.alerts[0] }} {{ $t("warnings.card.types.epi") }}</span>   
                    </div>

                    <div class="container has-text-underlined">
                    <b-icon class="has-right-space" icon="exclamation" type="is-primary"></b-icon>
                    <span class="is-size-6 has-text-weight-bold has-text-primary"> {{alerts.alerts[1] }} Red Zone</span>
                    </div>
                    <span class="has-text-grey-light is-custom-size">ID:  {{alerts["_id"]["%oid"] }}</span>
                </div>
                <div class="column is-3">                
                    <div class="has-rounded-border has-text-grey-dark has-text-centered">                        
                        <span class="has-text-weight-bold is-size-8 is-block">{{new Date(alerts.timestamp['%numberlong']).toLocaleDateString()}}</span>
                       
                    </div>
                    <div class="has-rounded-border has-text-grey-dark has-text-centered">                       
                        <span class="is-size-8 is-block">{{ new Date(alerts.timestamp['%numberlong']).toLocaleTimeString('pt-BR',{hour: '2-digit', minute:'2-digit'})}}</span>
                    </div>
                </div>
                    <div class="column is-5">                    
                        <img class="has-pointer-cursor" @click="showModalOcyan(alerts.firebase_img_url)" :src="alerts.firebase_img_url" />
                        
                    </div>
                </div>
                    <b-modal v-model="IsImageModalActiveOcyan" >
                    
                        <img class="has-pointer-cursor" id='modalOcyan' :src="url" />
                
                    </b-modal>                  
                </div>
            </div>
            </div>
             <div class="container box" v-if="warningsOcyan.length > 0">
                    <b-pagination :simple="true" :total="warningsOcyan.length" :current.sync="currentPageOcyan" :per-page="WarningsPerPageOcyan" order="is-centered">
                    </b-pagination>
                </div>
        </section>
        <!-- Fim da seção criada exclusivamente para o cliente Ocyan -->

        <!-- Seção criada para os demais clientes -->
        <section class="section" v-else>
            <div class="container is-flex is-flex-elements-centered">
                <b-field label-position="inside" grouped group-multiline position="is-centered">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">{{ $t('warnings.calendar.label') }}</span>
                    </template>
                    <b-datepicker v-model="SelectedDate" :month-names="GetCalendarLocale.MonthNames" :day-names="GetCalendarLocale.DayNames" :selectable-dates="WarningsDatesAvailable" icon="calendar-alt" trap-focus>
                    </b-datepicker>
                    <p class="control">
                        <b-button class="button is-primary" @click="GetSelectedDateWarnings({stayOnSamePage: false, ignoreCacheCheck: false})" expanded><span class="is-family-sans-serif">{{ $t('warnings.calendar.button') }}</span></b-button>
                    </p>
                    <p class="control">
                        <b-button class="button is-primary" @click="GetRecentWarnings" expanded>
                            <b-icon icon="sync" size="is-small"></b-icon>
                        </b-button>
                    </p>
                    <!-- <p class="control">
                        <b-button class="button is-info" @click="GetMonthWarnings" expanded><span class="is-family-sans-serif">Baixar alertas do mês</span></b-button>
                    </p> -->
                </b-field>
            </div>
            <section class="section" v-if="Warnings">
                <b-loading :is-full-page="false" :can-cancel="false" v-model="AreWarningsLoading"></b-loading>
                <div class="container box" v-if="Warnings.length > 0">
                    <div class="columns is-vcentered">
                        <div class="column">
                            <b-pagination :simple="true" :total="TotalWarnings" :current.sync="CurrentPage" :per-page="WarningsPerPage" order="is-centered">
                            </b-pagination>
                        </div>
                        <div class="column" v-if="WarningsFiltersAvailable.length > 0">
                            <b-dropdown aria-role="list" v-model="SelectedFilter">
                                <b-button type=" is-primary" slot="trigger" slot-scope="{ active }" outlined>
                                    <template>
                                        <span>Filtrar por preset ({{ SelectedFilter }})</span>
                                        <b-icon :icon="active ? 'arrow-up' : 'arrow-down'" size="small"></b-icon>
                                    </template>
                                </b-button>
                                <b-dropdown-item v-for="preset in WarningsFiltersAvailable" v-bind:key="preset" :value="preset" aria-role="listitem" class="has-text-left" @click="GetSelectedDateFilteredWarnings(parseInt(preset))">
                                    Preset: {{ preset }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="column">
                            <span class="is-size-5 has-text-primary" v-if="!ShowingFilteredWarnings">
                                <!-- Alertas não visualizados: <span class="has-text-weight-bold">{{ TotalWarnings - TotalSeenWarnings }}</span> -->
                                </span>
                            <b-button type="is-primary" @click="GetSelectedDateWarnings({stayOnSamePage: false, ignoreCacheCheck: true})" v-else outlined>Resetar filtros</b-button>
                        </div>
                        <div class="column">
                            <b-dropdown v-model="WarningsPerPage" aria-role="list">
                                <b-button type="is-primary" slot="trigger" slot-scope="{ active }" outlined>
                                    <template>
                                        <span>Alertas por página ({{ WarningsPerPage }})</span>
                                        <b-icon :icon="active ? 'arrow-up' : 'arrow-down'" size="small"></b-icon>
                                    </template>
                                </b-button>
                                <b-dropdown-item :value="5" aria-role="listitem" class="has-text-left">
                                    5
                                </b-dropdown-item>
                                <b-dropdown-item :value="10" aria-role="listitem" class="has-text-left">
                                    10
                                </b-dropdown-item>
                                <b-dropdown-item :value="15" aria-role="listitem" class="has-text-left">
                                    15
                                </b-dropdown-item>
                                <b-dropdown-item :value="20" aria-role="listitem" class="has-text-left">
                                    20
                                </b-dropdown-item>
                                <b-dropdown-item :value="25" aria-role="listitem" class="has-text-left">
                                    25
                                </b-dropdown-item>
                                <b-dropdown-item :value="30" aria-role="listitem" class="has-text-left">
                                    30
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <CardWarning v-for="warning in GetCurrentPageWarnings" v-bind:key="warning.datetime" :WarningObject="warning" v-on:UnseenWarningRemoved="GetSelectedDateWarnings({stayOnSamePage: true, ignoreCacheCheck: true})" v-on:SeenWarningRemoved="SeenWarningRemovedHandler()" v-on:WarningSeen="TotalSeenWarnings += 1" />
                <div class="container box" v-if="Warnings.length > 0">
                    <b-pagination :simple="true" :total="TotalWarnings" :current.sync="CurrentPage" :per-page="WarningsPerPage" order="is-centered">
                    </b-pagination>
                </div>
            </section>
        </section>
    </div>
</template>
<style lang="scss" scoped>
.is-flex-elements-centered {
    align-items: center;
    justify-content: center;
}
.is-observation {   
    float: right;
    margin-top: -30px;
    margin-right: 50px;
    display: inline-block;
}
#alerta_nao_classificado {
    font-size: 100%;
    color: rgb(245, 46, 11);
}

.has-text-underlined {
  border-bottom: 2px solid rgba(42, 157, 143, 0.3);
}
img {
  display: block;
}

.is-custom-size{
    font-size: 1.4vh;
}

.has-right-space {
  margin-right: 0.5vw;
}

.has-pointer-cursor {
  cursor: pointer;
}
.is-floating-left {
  float: right;
  margin-top: -30px;
  margin-right: -30px;
  display: inline-block;
}

.is-floating-right {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  display: inline-block;
}
.has-rounded-border {
    padding: .01vh .10vw .01vh;
    border: .5px solid grey;
    border-radius: 8px;
    margin-top: 1vh;
}

.is-size-8 {
    font-size: 1.60vh;
}
.imgOcyan{
    height: 60vh;
    width: 120vh;
}
.is-floating-left-ocyan {
    float: right;
    margin-top: -60px;
    margin-right: -30px;
    display: inline-block;
}

.is-floating-right-ocyan {
    float: right;
    margin-top: -60px;
    margin-right: 10px;
    display: inline-block;
}

</style>
<script>
import axios from 'axios'
import TheNavbar from '@/components/global/TheNavbar.vue'
import CardWarning from '@/components/warning/CardWarning.vue'
export default {
    name: 'Warning.vue',
    components: {
        TheNavbar,
        CardWarning
    },
    data() {
        return {
            Warnings: [], //Local array to store warnings as they are fetched from database or cache.
            WarningsDatesAvailable: [], //Local array to store all dates which have warnings on database.
            SelectedDate: null, //Bound to datepicker component and methods below to select a date to fetch warnings.
            WarningsPerPage: 5, //Number of warnings per page of pagination component.
            CurrentPage: 1, //Bound to current page of pagination component.
            TotalWarnings: 0, //Total number of warnings available on selected date. This info comes from database.
            TotalFilteredWarnings: 0,
            WarningsFiltersAvailable: [],
            SelectedFilter: null,
            ShowingFilteredWarnings: false,
            TotalSeenWarnings: 0,
            AreWarningsLoading: false,


            WarningsPerPageOcyan:6,
            currentPageOcyan:1,            
            warningsOcyan:[],
            datesOcyan:[],
            warningsDatesOcyan:[],
            IsImageModalActiveOcyan:false,
            SelectedDateOcyan:'',
            url:'',
            flagType:''
        }
    },
    computed: {
        //Return current logged user. Used on calls to fetch warning to database.
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        //Return slice of local array of warnings based on page user is right now.
        GetCurrentPageWarnings() {
            let pageNumber = this.CurrentPage - 1 //Decrease 1 on CurrentPage to match local warnings array index.
            let slicedArray = []
            slicedArray = this.Warnings.slice((pageNumber * this.WarningsPerPage), ((pageNumber + 1) * this.WarningsPerPage))
            return slicedArray
        },
          GetCurrentPageWarningsOcyan() {
            let pageNumber = this.currentPageOcyan - 1 //Decrease 1 on CurrentPage to match local warnings array index.
            let slicedArrayOcyan = []
            slicedArrayOcyan = this.warningsOcyan.slice((pageNumber * this.WarningsPerPageOcyan), ((pageNumber + 1) * this.WarningsPerPageOcyan))            
            return slicedArrayOcyan
        },
        //Return strings used on datepicker based on current locale.
        GetCalendarLocale() {
            if (this.$i18n.locale === 'pt-BR') {
                return {
                    MonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                    DayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                }
            } else {
                return {
                    MonthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Dezember'],
                    DayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                }
            }
        },
    },
    watch: {
        //Watch for changes on current logged user. Used to detect if user has changed their flag whilst this component is open.
        GetCurrentUser: {
            handler() {
                this.SelectedDateOcyan=''
                this.flagType= this.GetCurrentUser.flag.find(hirer =>hirer.name === this.GetCurrentUser.activeFlag)
                // if(this.GetCurrentUser.activeFlag.toLowerCase()=='ocyan'){
                if(this.flagType.type=='offshore'){
                    
                    this.GetWarningsOcyan()
                }
                else{
                this.Warnings = [] //Empty local warnings array so warnings from one operation don't mix with another.
                this.GetWarningsDatesAvailable() //Call method to check on dates available for the new operation selected.
                this.GetSelectedDateAvailableFilters()
                }
            },
            deep: true //Watch for changes on the entire user object, not just its first level.
        },
        //Watch for changes on current page of warnings pagination. Used to detect when user changes page to dispatch action to fetch a new batch of warnings from database.
        CurrentPage: {
            handler(currentPage) {
                // this.$gtag.event('changed_warning_page', { 'event_category': 'warnings' })
                this.CheckWarningsExpectedNumber(currentPage)
                // let warningDatetime = this.Warnings[this.Warnings.length - 1].datetime //Latest warning datetime from local array to use as a filter on next page query.
                // let expectedWarnings = currentPage * this.WarningsPerPage //Total warnings expected to be on local array on this page.
                // console.log('Excpected array length: ' + expectedWarnings)
                // console.log('Array length: ' + this.Warnings.length)
                // if (this.Warnings.length < expectedWarnings && this.Warnings.length !== this.TotalWarnings) { //Check if local warnings array is smaller than it should be on this page.
                //     this.$store.dispatch('database/GetWarningsPaginatedFromDatabase', { date: warningDatetime, flag: this.GetCurrentUser.activeFlag, limitToLast: this.WarningsPerPage + 1 })
                //         .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate local warnings array.
                // }
            }
        },
        // WarningsPerPage: {
        //     handler() {
        //         this.$gtag.event('changed_number_of_warnings_per_page', { 'event_category': 'warnings' })
        //     }
        // },
        // SelectedFilter: {
        //     handler() {
        //         this.$gtag.event('changed_warnings_preset_filter', { 'event_category': 'warnings' })
        //     }
        // }
    },
    methods: {
        //Used to fetch from database all dates which have warnings. Dates fetched here are displayed on the datepicker component.
        GetWarningsDatesAvailable() {
            this.$store.dispatch('database/GetWarningsDatesAvailable', this.GetCurrentUser.activeFlag) //Dispatch action to fetch dates.
                .then(snapshot => {
                    let warningDatesArray = [] //Create empty array to store fetched dates and process then before saving to this component's state.
                    for (let date in snapshot.val()) { //For each date fetched.
                        let modifiedDate = date.split('-') //Split dates by '-' delimiter to later save them on dd/mm/yyyy format.
                        warningDatesArray.push(new Date(modifiedDate[1] + '/' + modifiedDate[0] + '/' + modifiedDate[2])) //Push date to local array.
                    }
                    warningDatesArray.sort((dateOne, dateTwo) => { return dateOne - dateTwo }) //Sort local array of available dates on chronological order.
                    this.WarningsDatesAvailable = warningDatesArray //Save local array of available dates on this component's state.
                    this.GetRecentWarnings() //Call method to get last warnings from database.
                })
        },
        //Used to check if warnings from cache are up to date with warnings from remote.
        GetWarningsCacheStatus() {
            let lastWarningFromCache = this.$store.getters['database/GetWarningsCache'][0] //Get last warning from cache.
            if (lastWarningFromCache) { //Check if there is actually a warning on cache.
                return this.$store.dispatch('database/GetWarningsUnpaginatedFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag, limitToLast: 1 })
                    .then(snapshot => {
                        let cachedWarningDatetime = lastWarningFromCache.datetime //Save datetime of warning from cache.
                        let remoteWarningDatetime = null //Create variable to save datetime of warning from remote.
                        snapshot.forEach(childSnapshot => { remoteWarningDatetime = childSnapshot.val().datetime }) //Iterate over waninng fetched from remote.
                        return remoteWarningDatetime === cachedWarningDatetime ? true : false //Return true or false whether warning from cache is up to date with remote or not.
                    })
            } else {
                return false //Return false when there is no cache.
            }
        },
        //Populate this component's state with either warnings from cache or from remote.
        //Payload: { warnings: warningArray, cache: true/false }
        PopulateWarningsArray(payload) {
            if (payload.cache) { //Check if payload contains a flag indicating that warnings are from cache.
                this.Warnings = payload.warnings //Save warnings from cache to this component's state.
            } else { //If payload contains a flag indicating that warnings are from remote.
                let temporaryArray = [] //Create local empty array to store warnings after processing them.
                payload.warnings.forEach(warning => { //For each warning from remote on payload.
                    const tempWarning = warning.val()
                    tempWarning.key = warning.key
                    if (!this.Warnings.some(item => item.datetime === warning.val().datetime)) temporaryArray.push(tempWarning) //Push warning to local array if it isn't there yet. Done like this because paginated query from Firebase is inclusive, so it fetches at least one repeated alert every page.
                })
                temporaryArray.sort((dateOne, dateTwo) => { return dateTwo.datetime - dateOne.datetime }) //Sort array so warnings are ordered from last to older.
                temporaryArray.forEach(warning => { this.Warnings.push(warning) }) //Push each warning from local array to this component's state.
                this.$store.dispatch('database/SetWarningsCache', this.Warnings) //Dispatch action to store to save current warnings on this component's state to cache.)
            }
            this.UpdateWarningsCounts()
            this.CheckWarningsExpectedNumber(this.CurrentPage)
            this.AreWarningsLoading = false
        },
        //Used to fetch last warnings from database. Async because it needs to wait for a check on warnings cache, done by a method that returns a promise.
        async GetRecentWarnings() {
            this.AreWarningsLoading = true
            this.SelectedFilter = null
            this.ShowingFilteredWarnings = false
            this.Warnings = [] //Clear warnings on this component's state.
            this.CurrentPage = 1 //Reset to the first page of pagination.
            this.SelectedDate = this.WarningsDatesAvailable[this.WarningsDatesAvailable.length - 1] //Select last date from available dates on this component's state.
            let isWarningsCacheUpdated = await this.GetWarningsCacheStatus() //Call method to check if there are warnings on cache. Wait for promise return.
            if (isWarningsCacheUpdated) { //Check if warnings cache is up to date, then call method to populate this component's state with warnings from cache.
                this.PopulateWarningsArray({ warnings: this.$store.getters['database/GetWarningsCache'], cache: true })
            } else { //If warnings on cache are not up to date, dispatch action to get the last warnings from database.
                this.$store.dispatch('database/SetWarningsCache', []) //Clear previous warnings cache.
                this.$store.dispatch('database/GetWarningsUnpaginatedFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag, limitToLast: 30 }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
                    .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate this component's state with warnings from remote.
            }
            this.CheckWarningsExpectedNumber()
        },
        //Used to fetch warnings from database on a specific date issued by user on datepicker component. Async because it needs to wait for a check on warnings cache, done by a method that returns a promise.
        async GetSelectedDateWarnings(options) {
            // this.$gtag.event('requested_warnings_from_date', { 'event_category': 'warnings', })
            this.AreWarningsLoading = true
            this.SelectedFilter = null
            this.ShowingFilteredWarnings = false
            this.Warnings = [] //Clear warnings on this component's state.
            if (!options.stayOnSamePage) this.CurrentPage = 1 //Reset to the first page of pagination if option passed as parameter.
            let isThereWarningsCache = await this.GetWarningsCacheStatus() //Call method to check if there are warnings on cache. Wait for promise return.
            if (!options.ignoreCacheCheck && isThereWarningsCache) { //Check if warnings cache is up to date, then call method to populate this component's state with warnings from cache. Will not check for cache if option passed as parameter is true.
                this.PopulateWarningsArray({ warnings: this.$store.getters['database/GetWarningsCache'], cache: true })
            } else { //If warnings on cache are not up to date, dispatch action to get the last warnings from database.
                this.$store.dispatch('database/SetWarningsCache', []) //Clear previous warnings cache.
                this.$store.dispatch('database/GetWarningsUnpaginatedFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag, limitToLast: 30 }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
                    .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate this component's state with warnings from remote.
            }
        },
        CheckWarningsExpectedNumber(currentPage) {
            if (!this.Warnings.length) return
            let warningDatetime = this.Warnings[this.Warnings.length - 1].datetime //Latest warning datetime from local array to use as a filter on next page query.
            let expectedWarnings = currentPage * this.WarningsPerPage //Total warnings expected to be on local array on this page.
            if (this.Warnings.length < expectedWarnings && this.Warnings.length !== this.TotalWarnings) { //Check if local warnings array is smaller than it should be on this page.
                this.$store.dispatch('database/GetWarningsPaginatedFromDatabase', { date: warningDatetime, flag: this.GetCurrentUser.activeFlag, limitToLast: this.WarningsPerPage + 1 })
                    .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate local warnings array.
            }
        },
        UpdateWarningsCounts() {
            if (!this.ShowingFilteredWarnings) {
                this.$store.dispatch('database/GetWarningsCountFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag })
                    .then(snapshot => {
                        this.TotalWarnings = snapshot.val()
                        return this.$store.dispatch('database/GetSeenWarningsCountFromDatabase', { dateString: this.SelectedDate.toLocaleDateString('pt-BR').split('/').join('-'), flag: this.GetCurrentUser.activeFlag })
                    })
                    .then(snapshot => { this.TotalSeenWarnings = snapshot.val() })
            } else {
                this.TotalWarnings = this.Warnings.length
            }
        },
        SeenWarningRemovedHandler() {
            this.$store.dispatch('database/GetSeenWarningsCountFromDatabase', { dateString: this.SelectedDate.toLocaleDateString('pt-BR').split('/').join('-'), flag: this.GetCurrentUser.activeFlag })
                .then(snapshot => {
                    this.TotalSeenWarnings = snapshot.val() - 1
                    this.$store.dispatch('database/SetSeenWarningsCountOnDatabase', { dateString: this.SelectedDate.toLocaleDateString('pt-BR').split('/').join('-'), flag: this.GetCurrentUser.activeFlag, seenWarnings: this.TotalSeenWarnings })
                        .then(() => { this.GetSelectedDateWarnings({ stayOnSamePage: true, ignoreCacheCheck: true }) })
                })
        },
        GetSelectedDateAvailableFilters() {
            this.WarningsFiltersAvailable = []
            this.$store.dispatch('database/GetWarningsFiltersAvailable', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag })
                .then(snapshot => {
                    if (snapshot.exists()) this.WarningsFiltersAvailable = Object.keys(snapshot.val())
                })
        },
        GetSelectedDateFilteredWarnings(preset) {
            return this.$store.dispatch('database/GetWarningsPresetFilteredFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag, limitToLast: 30, presetID: preset })
                .then(snapshot => {
                    if (snapshot.exists()) {
                        this.Warnings = []
                        this.ShowingFilteredWarnings = true
                        this.PopulateWarningsArray({ warnings: snapshot, cache: false })
                    } else {
                        this.LaunchToast('Não há alertas com esse filtro.', 'is-danger')
                        this.SelectedFilter = null
                    }
                })
        },
        // GetMonthWarnings() {
        //     for (let day = 1; day <= 31; day++) {
        //         let databasePath = 'sauron/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/' + this.AddZero(day) + '-' + this.AddZero(this.SelectedDate.getMonth()) + '-' + this.SelectedDate.getFullYear()
        //         console.log(databasePath)
        //     }
        // },
        DetectScrollToBottom() {
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
                this.$gtag.event('scroll_end_warning_page', { 'event_category': 'warnings' })
            }
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000,
            })
        },
        AddZero(number) {
            number < 10 ? number = '0' + number : number
            number.length > 2 ? number = number.substring(1) : number
            return number.toString()
        },
        // funçõe exclusivas para ocyan
        GetSelectedDateWarningsOcyan(){
            let cia = this.GetCurrentUser.activeFlag.toLowerCase()
            let dateSelected = new Date(this.SelectedDateOcyan).toLocaleDateString('pt-BR').split('/').reverse().join('/')       
            if(this.warningsOcyan.length<1000){
                // axios.get("https://us-central1-harpia-projetos.cloudfunctions.net/app/getAlerts/7Hf5KVLZ0A0nlIKNCkahhFSHJ/approved/"+dateSelected)
                // http://localhost:5001/harpia-projetos/us-central1/app/getAlerts/valaris/x8PekyickECcNyUf2P/date-index
                // axios.get("http://localhost:5001/harpia-projetos/us-central1/app/getAlerts/"+cia+"/key0/approved/"+dateSelected)
                axios.get("https://us-central1-harpia-projetos.cloudfunctions.net/app/getAlerts/"+cia+"/key0/approved/"+dateSelected)
                .then(r=>{
                    this.warningsOcyan=[]
                    if(r.data!=''){
                        Object.values(r.data).forEach(element => {
                            if (!Object.keys(element).includes('_id')){
                                    element._id = {"%oid":element.identificador}
                                    element.thumb_up==false?element.thumb_up='false':element.thumb_up='true'
                                    element.thumb_down==false?element.thumb_down='false':element.thumb_down='true'
                                    element.timestamp['%numberlong']=element.timestamp['%numberLong']
                                    element.firebase_img_url=element.firebase_image_url                                                                            
                                    }                                
                                        this.warningsOcyan.push(element)
                                    
                        });
                    }
                })
                .finally(()=>{
                    // axios.get("http://localhost:5001/harpia-projetos/us-central1/app/getAlerts/"+cia+"/key0/disapproved/"+dateSelected)
                axios.get("https://us-central1-harpia-projetos.cloudfunctions.net/app/getAlerts/"+cia+"/key0/disapproved/"+dateSelected)
                    .then(r=>{
                        if(r.data!=''){
                            Object.values(r.data).forEach(element => {
                                if (!Object.keys(element).includes('_id')){
                                    element._id = {"%oid":element.identificador}
                                    element.thumb_up==false?element.thumb_up='false':element.thumb_up='true'
                                    element.thumb_down==false?element.thumb_down='false':element.thumb_down='true'
                                    element.timestamp['%numberlong']=element.timestamp['%numberLong']
                                    element.firebase_img_url=element.firebase_image_url                                                                            
                                    }
                                   
                                        this.warningsOcyan.push(element)
                                    
                            });
                        }
                    })
                    .finally(()=>{
                        this.warningsOcyan.sort((a,b) => (a.timestamp['%numberlong'] > b.timestamp['%numberlong']) ? -1 : ((b.timestamp['%numberlong'] > a.timestamp['%numberlong']) ? 1 : 0))
                        console.log(this.warningsOcyan)
                    })
                })
            }
        },
        GetWarningsOcyan(){
            let cia = this.GetCurrentUser.activeFlag.toLowerCase()
            this.warningsDatesOcyan=[]

           // this.warningsOcyan=[]                    
            // axios.get("https://us-central1-harpia-projetos.cloudfunctions.net/app/getAlerts/7Hf5KVLZ0A0nlIKNCkahhFSHJ/date-index").then(response=>{
            axios.get("https://us-central1-harpia-projetos.cloudfunctions.net/app/getAlerts/"+cia+"/key0/date-index").then(response=>{
            //axios.get("http://localhost:5001/harpia-projetos/us-central1/app/getAlerts/"+cia+"/key0/date-index").then(response=>{
                Object.keys(response.data).forEach(date => {
                    let newDt = date.split('-')
                    this.warningsDatesOcyan.push(new Date(newDt[1] + '/' + newDt[0] + '/' + newDt[2]))
                })       
                this.SelectedDateOcyan = this.warningsDatesOcyan.sort((a,b)=>{if(new Date(a).getTime()>new Date(b).getTime()){return 1}else{return -1}}).slice(-1)
                this.GetSelectedDateWarningsOcyan()
            })
        },
        showModalOcyan(url){            
            this.url= url
            this.IsImageModalActiveOcyan= !this.IsImageModalActiveOcyan
        }
    },
    created() {
        
        if (this.GetCurrentUser.activeFlag) {            
             this.flagType= this.GetCurrentUser.flag.find(hirer =>hirer.name === this.GetCurrentUser.activeFlag)             
                // if(this.GetCurrentUser.activeFlag.toLowerCase()=='ocyan'){
                if(this.flagType.type=='offshore'){
                    setTimeout(() => {
                        this.GetWarningsOcyan()
                    }, 500);
                   
                }else{
            this.GetWarningsDatesAvailable() //Call method to get last warnings from database as soon as user object is ready.
            this.GetSelectedDateAvailableFilters()}
        }
      
    },
    mounted() {
        window.addEventListener('scroll', this.DetectScrollToBottom)
    
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.DetectScrollToBottom)
        this.warningsOcyan=[]
    }
}
</script>